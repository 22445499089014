import { preserveQueryStringParams } from "@components/AppLink";
import { isNullOrUndefined } from "@helpers/isNullOrUndefined";
import names from "../constants/controlNames";
import ClientLogging from "../providers/loggingProvider";
import http from "../services/httpService";
import AppStore from "../store/AppStore";

//Caravan address lookup
//Different constants assignment for Caravan product
const product = `${import.meta.env.VITE_PRODUCT}`;
let prefix, currentPageIsCaravanDetails;

export const postcodeControlNames = () => {
  const currentPage = AppStore.getCurrentPageName();
  currentPageIsCaravanDetails = (product === "caravan" && currentPage === "Caravan details") || false;
  prefix = (currentPageIsCaravanDetails) ? product : "";

  return {
    postcodeName: names[transformProperty("postcodeName")],
    postcodeDropdownControlName: names[transformProperty("postcodeDropdownControlName")],
    houseNumberManualControlName: names[transformProperty("houseNumberManualControlName")],
    streetControlName: names[transformProperty("streetControlName")],
    cityControlName: names[transformProperty("cityControlName")],
    townControlName: names[transformProperty("townControlName")],
    countyControlName: names[transformProperty("countyControlName")],
    houseNameHiddenControlName: names[transformProperty("houseNameHiddenControlName")],
    houseNumberHiddenControlName: names[transformProperty("houseNumberHiddenControlName")],
  }
};

export const transformProperty = (input) => {
  const newValue = (currentPageIsCaravanDetails) ? String(input).replace(/^\w/, (c) => c.toUpperCase()) : input;
  return `${prefix}${newValue}`;
};

export async function FindAddress(postcode) {
  return http
    .get(`/${import.meta.env.VITE_CLIENTREQUESTS_API_ENDPOINT}/getpostcodeaddresses`, {
      params: { postcode: postcode },
    })
    .catch((error) => {
      ClientLogging.logInfo(error, "Find address");
      if (error.response.status === 440) {
        window.location.replace(
          preserveQueryStringParams(`${import.meta.env.VITE_SITE_ROUTE}/nosession`)
        );
      } else if (error.response.status === 500) {
        window.location.replace(
          preserveQueryStringParams(`${import.meta.env.VITE_SITE_ROUTE}/applicationerror`)
        );
      } else {
        ClientLogging.logError(error, "Find address");
      }
    });
}

export async function GetAddressDetails(key) {
  return http
    .get(`/${import.meta.env.VITE_CLIENTREQUESTS_API_ENDPOINT}/getaddressdetails`, {
      params: { key: key },
    })
    .catch((error) => {
      ClientLogging.logInfo(error, "Find address");
      if (error.response.status === 440) {
        window.location.replace(
          preserveQueryStringParams(`${import.meta.env.VITE_SITE_ROUTE}/nosession`)
        );
      } else if (error.response.status === 500) {
        window.location.replace(
          preserveQueryStringParams(`${import.meta.env.VITE_SITE_ROUTE}/applicationerror`)
        );
      } else {
        ClientLogging.logError(error, "Find address");
      }
    });
}

export const formatPostcode = (input) => {
  let newValue = String(input).replace(/\s+/g, "");

  let pcdLength = newValue.length;
  return `${newValue.substring(0, pcdLength - 3)} ${newValue.substring(pcdLength - 3)}`
    .toUpperCase()
    .trim();
};

export const formatAddressValue = (input) => {
  return String(input).replace(/\./g, "").replace(/-/g, " ").replace(/\//g, " stroke ");
};

export const postcodeButtonClickHandler = (
  lookupVersion = "v2",
  houseNameNumberValue
) => {
  const newNames = postcodeControlNames();
  const postcodeValue = formatPostcode(AppStore.getControlByName(newNames.postcodeName));
  const houseNumberValue = lookupVersion === "v1" ? undefined : houseNameNumberValue;

  deleteAddressFormData([newNames.postcodeName]);
  AppStore.setAddressState({ addressIsManual: false });

  // event, method
  // if the cleaned postcode input does not equal an empty string, use the FindAddress function
  if (postcodeValue !== "") {
    AppStore.setAddressState({
      addressEmptyError: false,
    });
    FindAddress(postcodeValue)
      .then((response) => {
        // hide the address list until the list values are set
        AppStore.setAddressState({
          addressListVisible: false,
        });
        // if the response is defined...
        if (response !== undefined) {
          // if the response is an error, hide the address list and show an error message
          if (response.data.isError) {
            ClientLogging.logInfo(response.data.isError, "find address");
            AppStore.setAddressState({
              addressNotListedError: true,
            });
          } else {
            // if the response is not an error:

            // we use filtered data to match response addresses with the house number / name input by the user
            let isFiltered = false;
            let filteredResponseData;
            let responseData = response.data.content;

            // if an input has been made in the house number / name field, set isFiltered to true
            if (!isNullOrUndefined(houseNumberValue) && houseNumberValue !== "") {
              isFiltered = true;

              // filter the response data to find matches with the input house number / name
              filteredResponseData = responseData.filter((element) => {
                if (
                  element.description
                    .toLowerCase()
                    .includes(houseNumberValue.toLowerCase())
                ) {
                  return true;
                }
              });

              responseData = filteredResponseData;

              // If there are no matches, display the appropriate error message
              if (responseData.length < 1) {
                ClientLogging.logInfo(response.data.isError, "find address");
                AppStore.setAddressState({
                  addressNotListedError: true,
                });
                return;
              }
            }

            // if there is only one match, immediately set this data in a card.
            if (lookupVersion === "v2" && responseData.length === 1) {
              cardSetHandler(responseData[0], false);
            }

            const addressListInitialValues =
              lookupVersion === "v1"
                ? [
                  {
                    key: "",
                    description: "-- Please select from the addresses below --",
                  },
                  {
                    key: "AddressNotListed",
                    description: "My address isn't listed",
                  },
                ]
                : [{ key: "", description: "" }];

            const addressList = addressListInitialValues.concat(
              isFiltered ? filteredResponseData : response.data.content
            );

            AppStore.setAddressState({
              addressListValues: addressList,
            });

            // Show the address dropdown list if a house number/name has not been entered,
            // or if there is only one address in the filtered list
            if (!isFiltered || (isFiltered && filteredResponseData.length !== 1)) {
              AppStore.setAddressState({
                addressListVisible: true,
                addressNotListedError: false,
              });
            }

            AppStore.setFormData({ [newNames.postcodeName]: postcodeValue });
          }
        }
      })
      .catch((ex) => {
        ClientLogging.logError(ex, "Find address");
      });
  } else if (postcodeValue === "") {
    AppStore.setAddressState({
      addressEmptyError: true,
      addressNotListedError: false,
    });
  }
};

export const updateManualInputVisibility = (isVisible) => {
  const newNames = postcodeControlNames();

  if (isVisible) {
    AppStore.setFormData({
      [names.postcodeDropdownControlName]: "AddressNotListed",
    });
    AppStore.addControlToAvoidValidation(newNames.postcodeName); // don't validate for proposer-postcode if it is hidden.
  } else {
    AppStore.removeControlToAvoidValidation(newNames.postcodeName);
  }
  AppStore.setVisibilityControls({ [names.addressIsManualName]: isVisible });

  [
    names.postcodeManualControlName,
    names.houseNumberManualControlName,
    names.streetControlName,
    names.townControlName,
    names.cityControlName,
  ].forEach((controlName) => {
    //If values are not deleted, form won't pass validation
    if (!isVisible) {
      AppStore.deleteFormData([controlName]);
    } else {
      AppStore.setHasChanged(controlName, true);
    }
    AppStore.setVisibilityControls({ [controlName]: isVisible });
  });
};

// on component load, determine the state and present control accordingly
// (checks for manual input && info card visibility)
export const updateLookupStatusOnLoad = (usesInfoCard = false) => {
  const newNames = postcodeControlNames();
  const postcodeValue = AppStore.getControlByName(newNames.postcodeName);
  const houseNumberManualControlValue = AppStore.getControlByName(newNames.houseNumberManualControlName);
  const streetControlValue = AppStore.getControlByName(newNames.streetControlName);
  const cityControlValue = AppStore.getControlByName(newNames.cityControlName);

  AppStore.setAddressState({ addressListVisible: false });

  // the info card can be presented if all the required values to do so are present:
  const requiredValuesToCompleteCard = [
    postcodeValue,
    houseNumberManualControlValue,
    streetControlValue,
    cityControlValue,
  ];

  // is there any address data?
  if (
    requiredValuesToCompleteCard.some((value) => {
      if (isNullOrUndefined(value) || value === "") {
        return true;
      }
    })
  ) {
    AppStore.setAddressState({ infoCardVisible: false });
    AppStore.setHasChanged(newNames.postcodeName, false);

    // is there NO address data?
    if (
      requiredValuesToCompleteCard.every((value) => {
        if (isNullOrUndefined(value) || value === "") {
          return true;
        }
      })
    ) {
      AppStore.setAddressState({ addressIsManual: false });
    } else {
      AppStore.setAddressState({ addressIsManual: true });
    }
  } else {
    // we have all address data, show the info card
    AppStore.setHasChanged(newNames.postcodeName, true);

    if (usesInfoCard && !AppStore.getAddressState().addressIsManual) {
      AppStore.setAddressState({ infoCardVisible: true, addressIsManual: false });
    } else {
      AppStore.setAddressState({ infoCardVisible: false, addressIsManual: true });
    }
  }
};

export const deleteAddressFormData = (controlsToKeep = []) => {
  const controlsToDelete = [
    names.postcodeName,
    names.houseNumberControlName,
    names.postcodeManualControlName,
    names.houseNumberManualControlName,
    names.streetControlName,
    names.townControlName,
    names.countyControlName,
    names.houseNameHiddenControlName,
    names.houseNumberHiddenControlName,
    names.cityControlName,
  ];
  const caravanControlsToDelete = [
    names.caravanPostcodeName,
    names.caravanHouseNumberControlName,
    names.caravanPostcodeManualControlName,
    names.caravanHouseNumberManualControlName,
    names.caravanStreetControlName,
    names.caravanTownControlName,
    names.caravanCountyControlName,
    names.caravanHouseNameHiddenControlName,
    names.caravanHouseNumberHiddenControlName,
    names.caravanCityControlName,
  ];

  const currentPageIsCaravanDetailssss = (window.location.pathname?.includes("caravan"));
  if (!currentPageIsCaravanDetailssss) {
    controlsToDelete.forEach((control) => {
      if (!controlsToKeep.includes(control)) AppStore.deleteFormData(control);
    });
  }
  else {
    caravanControlsToDelete.forEach((control) => {
      if (!controlsToKeep.includes(control)) AppStore.deleteFormData(control);
    });
  }
};

// cardSetHandler is used to set the data that is presented within the address lookup card
export const cardSetHandler = (data, isEvent = true, version = "v2") => {
  const newNames = postcodeControlNames();
  let postcodeValue = isEvent ? data.target.value : data.key; // a different format of data is sent depending on the event type.
  let postcodeToSet = formatPostcode(AppStore.getControlByName(newNames.postcodeName)); // hardSetPostcode is used to ensure 'proposer-postcode' stays set.
  AppStore.setDisableContinue(true);

  GetAddressDetails(postcodeValue)
    .then((response) => {
      const newValues = {
        [newNames.postcodeName]: postcodeToSet,
        [names.postcodeManualControlName]: postcodeToSet.split("~")[0],
        [newNames.postcodeDropdownControlName]: postcodeToSet.split("~")[0],
        [newNames.houseNumberManualControlName]: response.data.content.houseNumber
          .concat(" ", response.data.content.houseName)
          .trim()
          .replace(/\//g, " stroke "),
        [newNames.streetControlName]: formatAddressValue(response.data.content.street),
        [newNames.townControlName]: formatAddressValue(response.data.content.locality),
        [newNames.cityControlName]: formatAddressValue(response.data.content.town),
        [newNames.countyControlName]: formatAddressValue(response.data.content.county),
        [newNames.houseNameHiddenControlName]: response.data.content.houseName,
        [newNames.houseNumberHiddenControlName]: response.data.content.houseNumber,
      };


      Object.keys(newValues).forEach((key) => {
        AppStore.setFormData({ [key]: newValues[key] });
      });

      if (version === "v1") {
        AppStore.setAddressState({
          addressListVisible: false,
          infoCardVisible: false,
          addressIsManual: true,
        });
      } else {
        if (
          newValues[names.houseNumberManualControlName] !== "" &&
          newValues[newNames.streetControlName] !== "" &&
          newValues[newNames.cityControlName] !== ""
        ) {
          AppStore.setAddressState({
            addressListVisible: false,
            infoCardVisible: true,
            addressIsManual: false,
          });
          AppStore.setHasChanged(newNames.postcodeName, true);
        } else {
          AppStore.setAddressState({
            addressListVisible: false,
            infoCardVisible: true,
          });
          updateManualInputVisibility(true);
        }
      }
    })
    .finally(() => {
      AppStore.setDisableContinue(false);
    })
    .catch((error) => {
      ClientLogging.logError(error, "Get address details");
    });
};

export const handlePostcodeButtonClick = (validateControl, houseNameNumberValue) => {
  setTimeout(() => {
    const newNames = postcodeControlNames();
    // setTimeout ensures CLICK action is handled after the onBlur of the POSTCODE input
    const postcodeValidationEvent = {
      target: {
        name: newNames.postcodeName,
        value: formatPostcode(AppStore.getControlByName(newNames.postcodeName)),
      },
    };

    // if postcode value is valid, handle postcode click
    if (validateControl(postcodeValidationEvent)) {
      postcodeButtonClickHandler("v2", houseNameNumberValue);
    }
  }, 200);
};

// to show the manual address inputs, ensure all address data is wiped and no other elements or errors are visible
export const showManualEntryHandler = (ev) => {
  ev.preventDefault();
  deleteAddressFormData();
  const newNames = postcodeControlNames();
  AppStore.setHasChanged(newNames.postcodeName, false); // used for live validation - when we remove the info-card, we reset the validation (not showing an error) (DW)
  AppStore.setFormData({
    [names.postcodeDropdownControlName]: "AddressNotListed",
  });

  AppStore.setAddressState({
    addressListVisible: false,
    addressNotListedError: false,
    infoCardVisible: false,
    addressIsManual: true,
  });
};
